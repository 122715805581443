import { Component, OnInit, TemplateRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { forkJoin } from "rxjs";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { EventsService } from "src/app/services/events.service";
import { MasterService } from "src/app/services/master.service";
import { BookingService } from "src/app/services/booking.service";
import { PromotionCodeService } from "src/app/services/promotion-code.service";
import { displayDate } from "src/app/helpers";
import { EVENT_ROUTES } from "src/app/configs/event";

@Component({
  selector: "app-booking-event",
  templateUrl: "./booking-event.component.html",
  styleUrls: ["./booking-event.component.scss"],
})
export class BookingEventComponent implements OnInit {
  public currentDate = new Date();
  public isNotAvailable: boolean = false;

  public id: number = 0;
  public path: string = '';
  public event: any = {};
  public nationalities: Array<any> = [];
  public hotels: Array<any> = [];

  public isAccept: boolean = false;
  public selectedPrice: any = {};
  public formBooking: any = {};

  // date
  public minDate = new Date("2024-11-15");
  public maxDate = new Date("2024-11-16");
  public bsInlineValue = new Date();

  public modalRef: BsModalRef;
  public isReload: boolean = false;
  public isSave: boolean = false;

  readonly maxPax: number = 20;
  public maxAdult: number = this.maxPax;
  public maxChild: number = this.maxPax-1;
  public maxInfant: number = this.maxPax-1;

  public dialogPromotionCode: TemplateRef<any>;
  public promotionCodeMessage: string = "Promotion code is not available";
  public inputPromotionCode: any = {};
  private timeout: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private masterService: MasterService,
    private eventsService: EventsService,
    private modalService: BsModalService,
    private bookingService: BookingService,
    private promotionCodeService: PromotionCodeService,
  ) {}

  ngOnInit(): void {
    // this.id = +this.route.snapshot.params["id"];
    this.path = this.route.snapshot.params["id"];

    if (this.path) {
      this.callGetData();
    }
  }

  callGetData(): void {
    const code = EVENT_ROUTES[this.path];
    const payloadEvent = {
      id: this.id,
      code
    };
    forkJoin({
      nationalities: this.masterService.getNationalities(),
      hotels: this.masterService.getHotelJSON(),
      event: this.eventsService.getEvent(payloadEvent),
    }).subscribe((res: any) => {
      if (res) {
        this.nationalities = res.nationalities.data || [];
        this.hotels = res.hotels || [];
        this.event = res.event.data || {};

        this.minDate = new Date(this.event?.start_date);
        this.maxDate = new Date(this.event?.end_date);
        this.bsInlineValue = this.minDate;

        this.setForm();
        this.checkAvailale();
      }
    });
  }

  onInputPromotionCode() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.callGetPromotionCode();
    }, 500);
  }

  callGetPromotionCode(): void {
    const payload = {
      code: this.formBooking.bookingInfo.promotion_code,
      type: "EVENT",
      type_id: 4, // event
      reference_id: this.id, // event id
      reference_type_id: 3, // all
    }
    this.promotionCodeService.getPromotionCode(payload).subscribe({
      next: (data) => {
        this.inputPromotionCode = data.result;
        this.onCheckDiscount(data.result);
      }
    });
  }

  onCheckDiscount(data: any = this.inputPromotionCode) {
    if (data.travel_start && data.travel_end) {
      if (this.bsInlineValue < new Date(data.travel_start) || this.bsInlineValue > new Date(data.travel_end)) {
        this.formBooking.bookingInfo.is_active_discount = false;
          this.promotionCodeMessage = `
            Promotion code is not available <br/>(Can be used traveling between
            ${displayDate(new Date(data.travel_start), "DD MMM YYYY")} - 
            ${displayDate(new Date(data.travel_end), "DD MMM YYYY")})
          `;
        data.discount = 0;
        data.is_active = false;
      } else {
        this.promotionCodeMessage = `Promotion code is not available`;
      }
    } else {
      this.promotionCodeMessage = `Promotion code is not available`;
    }

    if (data.discount && data.is_active) {
      this.dialogPromotionCode = null;
      this.formBooking.bookingInfo.is_active_discount = true;

      this.formBooking.bookingInfo.discount_rate = data.is_percent ? "%" : "THB";
      this.calculateDiscount(data.discount, data.is_percent, true);
    } else {
      if (!this.isSave) {
        this.dialogPromotionCode = null;
      }
      this.formBooking.bookingInfo.is_active_discount = false;
      this.calculateDiscount();
    }
  }

  calculateDiscount(rate: number = 0, is_percent: boolean = false, is_reset: boolean = false) {
    let bookingInfo = this.formBooking.bookingInfo;

    bookingInfo.discount = rate;
    bookingInfo.discount_price = 0;

    if (is_percent) {
      bookingInfo.discount_price = bookingInfo.total_price * (bookingInfo.discount / 100);
    } else {
      bookingInfo.discount_price = bookingInfo.discount;
    }

    bookingInfo.discount_price = bookingInfo.discount_price > bookingInfo.total_price ? bookingInfo.total_price : bookingInfo.discount_price;
    bookingInfo.amount = bookingInfo.total_price - bookingInfo.discount_price;

    this.formBooking.bookingInfo = bookingInfo;

    // if (is_reset) {
    //   if (is_percent) {
    //     this.formBooking.bookingInfo.discount_price = this.formBooking.bookingInfo.total_price * (this.formBooking.bookingInfo.discount / 100);
    //   } else {
    //     this.formBooking.bookingInfo.discount_price = this.formBooking.bookingInfo.discount;
    //   }
    // }

    // this.formBooking.bookingInfo.discount_price = this.formBooking.bookingInfo.discount_price > this.formBooking.bookingInfo.total_price ? this.formBooking.bookingInfo.total_price : this.formBooking.bookingInfo.discount_price;

    // this.formBooking.bookingInfo.amount = this.formBooking.bookingInfo.total_price - this.formBooking.bookingInfo.discount_price;

    // if (this.isSave) {
    //   if (this.dialogPromotionCode) {
    //     this.toggleDialog(this.dialogPromotionCode);
    //   } else {
    //     this.saveBooking();
    //   }
    // }
  }

  checkAvailale(): boolean {
    // this.currentDate = new Date("2024-11-17 11:59:59"); // Mock test available
    this.currentDate = new Date();
    const startDate = new Date(this.event.booking_start);
    const closeDate = new Date(this.event.booking_end)
    const available: boolean = this.currentDate >= startDate && this.currentDate < closeDate;
    this.isNotAvailable = !available;
    return available;
  }

  setForm(): void {
    this.formBooking = {
      is_event: true,
      bookingInfo: {
        event_id: this.id,
        event_code: this.event.code,
        event_title: this.event.title,
        privacy: "",
        event_time: "",
        date_travel: "",
        pax: 1,
        adult_pax: 1,
        child_pax: 0,
        infant_pax: 0,
        adult_price: 0,
        child_price: 0,
        total_adult_price: 0,
        total_child_price: 0,
        total_price: 0,
        commission: 0,
        promotion_code: "",
        discount: 0,
        discount_rate: "",
        discount_price: 0,
        servicecharge: 0,
        insurance: null,
        payment_mode: "Selling price",
        payment_collect: "Guest",
        note_by: "Website",
        issued_by: "Website",
        isServicecharge: false,
        isInsurance: true,
        isCommission: false,
        operator: 1,
        booking_channel: 1,
        booking_sub_channel: 1,
        is_active_discount: false,
      },
      guestInfo: [
        {
          fullname: "",
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
          hotel: "other",
          hotel_other: "other",
          gender: "Male",
          nationality: "",
          is_primary: 1,
          passport_number: "",
        },
      ],
    };
  }

  onSelectedType(event: any): void {
    this.formBooking.bookingInfo.privacy = event.value;
    this.onSetPrice();
  }

  onSetPrice(): void {
    this.selectedPrice = this.event.types.find(
      (item: any) => item.type === this.formBooking.bookingInfo.privacy
    );
    if (this.selectedPrice) {
      this.callCulatePrice();
    }
  }

  callCulatePrice(): void {
    const bookingInfo = { ...this.formBooking.bookingInfo };
    const adultPrice = this.selectedPrice.adult_price * bookingInfo.adult_pax;
    const childPrice = this.selectedPrice.child_price * bookingInfo.child_pax;
    const totalPrice = adultPrice + childPrice;

    this.formBooking.bookingInfo = {
      ...bookingInfo,
      adult_price: adultPrice,
      child_price: childPrice,
      total_adult_price: adultPrice,
      total_child_price: childPrice,
      total_price: totalPrice,
      amount: totalPrice,
    };

    this.onCheckDiscount();
  }

  async onChangePax(value: any, ages: string = "") {
    const previousPax = this.formBooking.bookingInfo[ages];

    this.formBooking.bookingInfo[ages] = value;
    this.formBooking.bookingInfo.pax = this.formBooking.bookingInfo.adult_pax + this.formBooking.bookingInfo.child_pax + this.formBooking.bookingInfo.infant_pax;

    ages !== "infant_pax" && this.callCulatePrice();

    if (value > previousPax) {
      this.addGuest();
    } else {
      this.removeGuest();
    }
  }

  addGuest(): void {
    this.formBooking.guestInfo.push({
      fullname: "",
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      hotel: "other",
      hotel_other: "other",
      gender: "Male",
      nationality: "",
      is_primary: 0,
      passport_number: "",
    });
  }

  removeGuest(): void {
    if (this.formBooking.guestInfo.length > 1) {
      this.formBooking.guestInfo.pop();
    }
  }

  saveBooking(): void {
    if (this.currentDate > this.minDate) {
      return;
    }
    this.isSave = true;

    this.formBooking.guestInfo.map((item: any) => {
      item.fullname = `${item.firstname} ${item.lastname}`;
      return item;
    })

    this.formBooking.bookingInfo = {
      ...this.formBooking.bookingInfo,
      date_travel: this.bsInlineValue
    }

    const payload = {
      ...this.formBooking
    };

    const validGuest = this.validFormGuest();
    if (!validGuest) {
      alert('Invalid data, please fill in complete information.');
      return;
    }

    if (!this.checkAvailale()) {
      alert('This event is not available.');
      this.setForm();
      return;
    }

    this.isReload = true;
    this.bookingService.saveBooking(payload).subscribe(
      (res: any) => {
        if (res) {
          this.router.navigate(['event/payment', res.Transaction]);
        }
        this.isReload = false;
      }
    )
  }

  onAccept(): void {
    this.isAccept = !this.isAccept;
  }

  validFormGuest() {
    let valid: boolean = false;
    const guestInfo = [...this.formBooking.guestInfo]

    const isFirstname = guestInfo.every((item: any) => item.firstname)
    const isLastname = guestInfo.every((item: any) => item.lastname)
    const isGender = guestInfo.every((item: any) => item.gender)
    const isPassportNumber = guestInfo.every((item: any) => item.passport_number)
    const isNationality = guestInfo.every((item: any) => item.nationality)
    const isEmail = guestInfo.every((item: any, index: number) => index === 0 ? item.email : true)

    if (isFirstname && isLastname && isGender && isPassportNumber && isNationality && isEmail) {
      valid = true;
    }

    return valid;
  }

  // Toggle modal
  toggleDialog(dialog: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      dialog,
      Object.assign(
        {},
        { class: "gray modal-lg modal-dialog-centered", centered: true }
      )
    );
  }
}

const mockPayload = {
  is_event: true,
  bookingInfo: {
    event_id: 1,
    event_code: "TC-EV-01",
    event_title: "The Heaven Lantern 2023",
    privacy: "Standard",
    event_time: "",
    date_travel: "2023-11-27",
    pax: 3,
    adult_pax: 3,
    child_pax: 0,
    infant_pax: 0,
    adult_price: 4000,
    child_price: 2400,
    total_adult_price: 12000,
    total_child_price: 0,
    total_price: 10200,
    commission: 0,
    promotion_code: "",
    discount: 0,
    discount_rate: "",
    discount_price: 0,
    servicecharge: 0,
    insurance: null,
    payment_mode: "Selling price",
    payment_collect: "Guest",
    note_by: "Website",
    issued_by: "Website",
    isServicecharge: false,
    isInsurance: true,
    isCommission: false,
    operator: 1,
    booking_channel: 1,
    booking_sub_channel: 1,
  },
  guestInfo: [
    {
      fullname: "NongTest TestEvent",
      firstname: "NongTest",
      lastname: "TestEvent",
      email: "yuranannong@gmail.com",
      phone: "0123456789",
      hotel: "other",
      hotel_other: "other",
      gender: "Male",
      nationality: "Thai",
      is_primary: 1,
      passport_number: "111-111-111-111",
    },
    {
      fullname: "NongTest TestEvent",
      firstname: "NongTest",
      lastname: "TestEvent",
      email: "yuranannong@gmail.com",
      phone: "0123456789",
      hotel: "other",
      hotel_other: "other",
      gender: "Male",
      nationality: "Thai",
      is_primary: 0,
      passport_number: "222-222-222-222",
    },
  ],
};
